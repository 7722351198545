@import "../../scss/mixin.scss";

.location-hero {
    background-image: url("../../../public/images/diamonds.svg");
    background-attachment: fixed;
    background-repeat: repeat-y;
    background-size: cover;
    @include mobile {
        background-attachment: inherit;
    } 
}

.location-one {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 326px 0 100px;
    height: 600px;
    h1 {
    
        width: 50%;
        max-width: 1000px;
        text-align: center;
        @include mobile {
            width: 100%;
            padding: 0 20px;
        }
    }
    @include mobile {
        box-sizing: border-box;
        height:80vh;
        padding:94px 0 0 0;
    }
}

.location-two {
    @include common-padding;
    display: flex;
    flex-direction: column;
    .copy-image {
        display: flex;
        padding-bottom: 56px;
        @include mobile {
            flex-direction: column;
        }
        .copy {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            padding-right: 10%;
            @include mobile {
                width: 100%;
                padding-right: 0;
            }
            h2 {
                padding-bottom: 24px;
            }
        }
        .img-wrapper {
            width: 50%;
            @include mobile {
                width: 100%;
                padding-top: 48px;
            }
            img {
                width: 100%;
            }
        }
    }
}
.location-three {
    @include common-padding;
    .title-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 64px;
        @include mobile {
            flex-direction: column;
            padding-bottom: 32px;
        }
        .btns-wrapper {
            display: flex;
            @include mobile {
                flex-wrap: wrap;
                padding-top: 18px;
            }
            a {
                cursor: pointer;
                border: 1px solid $forest;
                margin: 0 8px;
                @include mobile {
                    margin: 4px 4px;
                }
                p {
                    color: $beige;
                    @include mobile {
                        font-size: 14px;
                    }
                }
                &:not(.active) {
                    background-color: $beige;
                    p {
                        color: $forest;
                    }
                }
                &:hover {
                    background-color: $forest;
                    p {
                        color: $beige;
                    }
                }
            }
        }
    }
    .content-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @include mobile {
            flex-direction: column-reverse;
        }
        img {
            width: 60%;
            @include mobile{
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        .lines {
            display: flex;
            flex-direction: column;
            width: 40%;
            @include mobile {
                flex-wrap: wrap;
                width: 100%;
                padding-top: 18px;
            }
            .line {
                .tube-svg {
                    margin-top: 8px;
                    width: 24px;
                    height: 19.2px;
                }
                display: flex;
                padding-bottom: 32px;
                .copy {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                }
                .tube-block {
                    display: flex;
                    .tube {
                        margin: 4px 8px 4px 0;
                        content: " ";
                        width: 32px;
                        height: 6px;
                        &.central {
                            background-color: #da4534;
                        }
                        &.liz {
                            background-color: #5a428e;
                        }
                        &.jub {
                            background-color: #6d7276;
                        }
                        &.bakerloo {
                            background-color: #894e24;
                        }
                        &.vic {
                            background-color: #00a0e2;
                        }
                        &.north {
                            background-color: #000000;
                        }
                        &.circle {
                            background-color: #ffce00;
                        }
                        &.metro {
                            background-color: #622252;
                        }
                        &.hammer {
                            background-color: #c69ead;
                        }
                    }
                }
            }
        }
    }
}

.swans{
    background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        @include mobile{
            background-position: 80% 50%;
            background-attachment: inherit;
        }
}

.location-four {
    display: flex;
    height: 100vh;
    .background {
        @include common-padding;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-attachment: fixed;
        width: 100%;
        @include mobile {
            background-position: 80% 50%;
            justify-content: center;
            background-attachment: inherit;
            background-color: rgba($color: #000000, $alpha: 0.4);
        }
        h1 {
            width: 33%;
            color: $beige;
            padding-bottom: 24px;
        }
        h2 {
            width: 40%;
            color: $beige;
            @include mobile {
                width: 100%;
            }
        }
    }
}

.location-five {
    @include common-padding;
    display: flex;
    @include mobile {
        flex-direction: column;
    }
    .title-wrapper {
        width: 35%;
        @include mobile {
            width: 100%;
        }
        h2 {
            width: 70%;
            @include mobile {
                width: 100%;
                padding-bottom: 32px;
            }
        }
    }
    .map-container{
        display: flex;
        overflow-x: auto;
        width: 100%;   
        white-space: nowrap;
        scrollbar-color: $forest $beige;
        scrollbar-width: thin;
          &::-webkit-scrollbar-thumb {
            background-color: $forest;            
          }
        
          &::-webkit-scrollbar-track {
            background-color: $beige; 
          }
        img {
            width: 100%;
            
            @include mobile {
                height: 400px;
                aspect-ratio: auto;
                width: auto;
                max-width: unset;

                
            }
        }
    }
}

.location-six {
    padding: 34px 0 100px 0;
    display: flex;
    justify-content: flex-end;
    @include mobile {
        flex-direction: column;
    }
    .image-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 12px;
        width: 28%;
        @include mobile {
            width: 100%;
        }
        img {
            width: 100%;
        }
        p {
            padding: 12px 0;
        }
        .copy-wrapper {
            display: flex;
            padding-top: 8px;
            padding-left: 8px;
            align-items: center;
            @include mobile {
                padding:10px 0 20px 0;
                align-items: baseline;
            }
            p {
                padding-left: 10px;
                @include mobile {
                    padding:0 0 0 10px;
                }
            }
            img {
                width: 16px;
                height: fit-content;
            }
        }
    }
}

.location-seven {
    @include common-padding;
    display: flex;
    flex-direction: column;
    .occ-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 64px 0;
        justify-content: space-between;
        @include mobile {
            justify-content: flex-start;
        }
        .occ {
            width: 14.25%;
            padding: 50px 13.5px 50px 13.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mobile {
                width: 33%;
            }            
        }
        &:last-child {
            border-top: 1px solid $beige-dark;
        }
    }
}

.key{
    display: flex;
    flex-direction: column;
    width: 40% ;
    @include mobile {
        flex-direction:row;
        flex-wrap:wrap;
        width:100%;
    }
    &.dark{
        .key-el{
            .diamond{
                &.yellow{

                    
                    p{
                        color: #a67c52;
                    }
                }
            }
        }
    }
    .key-el{
        display: flex;
        padding: 15px 0;
        @include mobile {
            flex:0 0 50%
        }
        .diamond{   
            width: 30px;  
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;   
            @include mobile {
                max-width: none;
            }         
            p{
                padding: 0;
                position: absolute;
            }            
            &.yellow{
                p{
                    color: $beige;
                }
            }
            &.brown{
                p{
                    color: $beige;
                }
            }
            &.black{
                p{
                    color: white;
                }
            }
        }
        p{
            padding: 0 12px;
        }
    }
}


