@import '../../scss/mixin.scss';


.avail-two {
    @include common-padding;
    display: flex;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
    }
    img {
        width: 45%;
        @include mobile {
            width: 100%;
        }
    }
    h2 {
        padding-bottom: 48px;
    }
    .table-wrapper {
        display: flex;
        flex-direction: column;
        width: 45%;
        @include mobile {
            width: 100%;
            padding-top: 56px;
        }        
        .row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 8px 0 8px 0;
            align-items: center;

         
             
                &:hover{
                    background-color: rgba($forest-light-3, 0.2);
                    cursor: pointer;
                }               
                &.no-hover{
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
            .cell {
                width: 25%;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &:first-child {
                    text-align: left;
                    justify-content: flex-start;
                }
            }
            &.border {
                border-bottom: 2px solid $forest;
                padding-bottom: 12px;
                margin-bottom: 40px;
                &:last-child {
                    padding-top: 40px;
                }
                &:nth-child(2){
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
                &:last-child{
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
            }
        }
    }
}


#floorplan-swiper{
    .swiper-slide{
        display: flex;
        justify-content: center;
    }
}
.copy{
    display: flex;
    justify-content: space-between;
    @include mobile{
        flex-direction: column-reverse;
    }
    .btns{
        display: flex;
        @include mobile{                        
            flex-wrap: wrap;
            padding-bottom: 32px;
        }
        a{
            margin: 0 4px;
            border: 1px solid $forest;
            cursor: pointer;
            @include mobile{
                margin: 4px;
                &:first-child{
                    margin-left: 0;
                }
                &:nth-child(4){
                    margin-left: 0;
                }
            }
            p{
                color: $beige;
                font-size: 14px;
            }
            &:not(.active){
                background-color: transparent;
                p{
                    color: $forest;
                }

            }
            &:hover{
                background-color: $forest;
                p{
                    color: $beige;
                }
            }
        }
    }
}

.table{
    padding: 32px 0;
    .row{
        display: flex;
        justify-content: space-between;
        width: 350px;
        padding-bottom: 8px;
        &:last-child{
            padding-bottom: 0;
        }
    }
}


.avail-two {
    @include common-padding;
    display: flex;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
    }
    img {
        width: 45%;
        @include mobile {
            width: 100%;
        }
    }
    h2 {
        padding-bottom: 48px;
    }
    .table-wrapper {
        display: flex;
        flex-direction: column;
        width: 45%;
        @include mobile {
            width: 100%;
            padding-top: 56px;
        }        
        .row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 8px 0 8px 0;
            align-items: center;

         
             
                &:hover{
                    background-color: rgba($forest-light-3, 0.2);
                    cursor: pointer;
                }               
            .cell {
                width: 25%;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &:first-child {
                    text-align: left;
                    justify-content: flex-start;
                }
            }
            &.border {
                border-bottom: 2px solid $forest;
                padding-bottom: 12px;
                margin-bottom: 40px;
                &:last-child {
                    padding-top: 40px;
                }
                &:nth-child(2){
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
                &:last-child{
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
            }
        }
    }
}