@import '../../../scss/mixin.scss';

#standard-swiper {
    width: 100%;
    .standard-image{
        height: 80vh;
        background-size: cover;
        background-position: 0%;
        @include mobile {
            height:80vw;
        }
    }
    img{
        width: 100%;
    }
    .pagination-progress {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        position: absolute;
        z-index: 2;

        .swiper-pagination-bullet {
            height: 2px;
            width: 24px;
            background-color: $beige;
            border-radius: 0;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            height: 2px;
            width: 80px;
        }
        @include mobile {
            padding: 8px 0;
        }
    }
}
