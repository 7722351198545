@import "./mixin.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
body {
  background-color: $beige;
}

.blue-back {
  background-color: $ice-blue;
}

.forest-back {
  background-color: $forest;
}

.leaf-back {
  background-image: url("../../public/images/building/2.jpg");
  background-size: cover;
  background-attachment: fixed;
}
