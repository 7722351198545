@import "../../../scss/mixin.scss";

// Wrapper
.tile-three-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile{
        flex-direction: column-reverse;
    }
    // Swiper
    #hero-swiper {
        width: 50%;
        margin: 0;
        position: relative;
        @include mobile{
            width: 100%;
        }
        .background-image{
            height: 80vh;
            width: 100%;
            background-size: cover;
            background-position: center;
            @include mobile{
                height: 40vh;
            }
        }
    }

    .pagination-progress {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        position: absolute;
        z-index: 2;

        .swiper-pagination-bullet {
            height: 2px;
            width: 24px;
            background-color: $beige;
            border-radius: 0;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            height: 2px;
            width: 80px;
        }
        @include mobile {
            padding: 8px 0;
        }
    }
    // Copy
    .copy-wrapper {
        width: 40%;
        @include mobile{
            width: 100%;
        }
        .copy-container {
            padding: 32px 0;
            h2 {
                color: $forest-light-3;
                cursor: pointer;
                &.active {
                    color: $forest;
                }
            }
        }
        @include mobile{
            a{

                display: none;
            }
        }
    }
}
