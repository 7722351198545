@import '../../../scss/mixin.scss';

#scrollbar-gallery{
    width: 100%;
    position: relative;
    padding-bottom: 80px;
    .swiper-slide{
        .copy-wrapper{
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
            padding-left: 8px;
            img{
                width: 20px;
                height: fit-content;
                padding-top: 5px
            }
            .copy{
                display: flex;
                flex-direction: column;
                padding-left: 16px;
            }
        }
        width: 35%;
        @include mobile{
            width: 80%;
        }
        img{
            width: 100%;

        }
    }
    .swiper-pagination {
        position: absolute;
        top: unset;
        bottom: 0;
        color: $forest;
        .swiper-pagination-progressbar-fill{
            background-color: $forest;
            position:absolute;            
        }
    }
}