@import "../../scss/mixin.scss";

.intro {
    position: absolute;
    height: 100vh;
    width: 100vw;
    mask-image: url('../../../public/images/diamondmask-test.svg');
    -webkit-mask-image: url('../../../public/images/diamondmask-test.svg');
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    z-index: 3;
    mask-size: 100% 250%;
    -webkit-mask-size: 200% 250%;
    @include mobile{
        mask-size: 200% 100%;
        -webkit-mask-size: 100vh 200%;
    }
    mask-position: center;
    -webkit-mask-position: center;
    transition: mask-size 0.5s ease-in-out;
    -webkit-transition: -webkit-mask-size 0.5s ease-in-out; 
    &.active {
        mask-size: 200% 200%;
        -webkit-mask-size: 200% 200%;
    }
}


.lottie-container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.masked-animation,
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.masked-animation {
    z-index: 1;
}

.intro
    > div:nth-child(1)
    > svg:nth-child(1)
    > g:nth-child(2)
    > g:nth-child(1)
    > g:nth-child(9)
    > g:nth-child(1) {
    path {
        
    }
}

