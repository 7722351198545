@import '../../scss/mixin.scss';

.hero{
    background-image: url('../../../public/images/diamonds.svg');
    background-attachment: fixed;
    background-repeat: repeat-y;    
    background-size: cover;
    @include mobile {
        background-attachment: inherit;
    } 
}

.tile-one{
    video{
        filter: brightness(0.8);
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;        
        @include mobile{
            height: 100vh;
            width: auto;
            aspect-ratio: unset;
        }
    }
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
        background-attachment: inherit;
    } 
    .title-wrapper{
        display: flex;
        color: $beige;
        text-align: center;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: relative;
        flex-direction: column;
        width: 100%;
        text-wrap: balance;
        p{
            color: $beige;
        }
        h1{
            color: $beige;
            position: relative;
            width: 60%;
            text-align: center;
            padding-top: 1.5rem;
            @include mobile{
                padding: 1.5rem 20px 0;
                width: 100%;

            }
        }
    }
    .arrow-wrapper{
        position: absolute;
        bottom: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3{
            color: $beige;
            padding-bottom: 1.5rem;
        }
    }
}

.tile-two-wrapper{    
    @include common-padding;
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: center;  
    .copy-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        text-align: center;
        @include mobile{
            width: 100%;
        }
        h2{
            padding: 32px 0;
            width: 50%;
            text-align: center;
            @include mobile{
                padding: 16px 0;
                width: 100%;
                
            }
        }
    }
    .collection{
        display: flex;
        gap: 1.5rem;
        width: 100%;
        padding: 6rem 0 0;
        @include mobile{
            flex-direction: column;
            padding: 3rem 0 0;
        }
        .sec-images{
            height: 90vh;
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            @include mobile{
                width: 100%;
                height:auto;
                gap: 1rem;
                flex-direction: row;
            }
            .second{
                background-image: url('../../../public/images/home/5.jpg');
                background-size: cover;
                height: 50%;
                width: 100%;
                content: ' ';                
                @include mobile{
                    height: 60vw;
                }
                &:nth-child(2){
                    background-image: url('../../../public/images/home/6.jpg');
                    @include mobile{
                        background-position: 100% 50%;
                    }
                }
            }
        }
        .img-wrapper{            
            width: 50%; 
            height: 100%;     
            content: ' ';      
            background-size: cover;
            background-image: url('../../../public/images/home/2.jpg');
            @include mobile{
                width: 100%;
                flex:0 0 80vw;
            }
            img{
                height: 100%;
            }
        }
    }
}

.tile-three-wrapper{
    display: flex;
    @include common-padding;
    @include mobile{
        padding: 1.5rem; 
    }
}

.tile-four-wrapper{
    display: flex;
    background-image: url('../../../public/images/home/3.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
    width: 100%;
    @include mobile {
        background-attachment: inherit;
    } 
    @include common-padding;
    .copy-wrapper{
        display: flex;
        flex-direction: column;
        position: relative;
        left: 50%;
        margin-top: auto;
        margin-bottom: auto;
        width: 50%;
        @include mobile{
            width: 100%;
            left: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
        h2{
            color: $beige;
            padding: 32px 0;
            width: 75%;
            @include mobile{
                width: 90%;
            }
        }
        p{
            color: $beige;
        }
        a{
            border-color: $beige;
            &:hover{
                border-color: $forest;
            }
        }
    }
}
.sustainability{
    &:hover{
        background-color: $beige !important;        
        p{            
            color: $forest !important;
        }
    }
}
