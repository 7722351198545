$beige: #f2ebd8;
$forest: #133c1e;
$beige-dark: #d1cab7;
$forest-dark: #0d2f16;
$beige-light: #f5f3ec;
$forest-light: #30593b;
$forest-light-2: #6e8875;
$forest-light-3: #8eb197;
$ice-blue: #e4e9e6;
$grass: #43b281;
$white: #ffffff;

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@font-face {
    font-family: "Suisse";
    src: url("../../public/fonts/suisse/SuisseBPIntl-Light.woff2")
            format("woff2"),
        url("../../public/fonts/suisse/SuisseBPIntl-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Berlingske";
    src: url("../../public/fonts/berlingske/BerlingskeSerif-Regular.woff2")
            format("woff2"),
        url("../../public/fonts/berlingske/BerlingskeSerif-Regular.woff")
            format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1 {
    font-family: "Suisse";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: 48px;
    line-height: 66px;
    color: $forest;
    @include mobile {
        font-size: 32px;
        line-height: 40px;
    }
    &.b{
        font-feature-settings: 'salt' on;
        font-family: 'Berlingske';
    }
}


h2 {
    font-family: "Suisse";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: 32px;
    line-height: 44px;
    color: $forest;
    @include mobile {
        font-size: 24px;
        line-height: 32px;
    }
    &.b{
        font-family: 'Berlingske';
        font-feature-settings: 'salt' on;
    }
}
h3 {
    font-family: "Suisse";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: 28px;
    line-height: 40px;
    color: $forest;
    @include mobile {
        font-size: 20px;
        line-height: 28px;
    }
    &.b{
        font-family: 'Berlingske';
        font-feature-settings: 'salt' on;
    }
}
h4 {
    font-family: "Suisse";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: 24px;
    line-height: 36px;
    color: $forest;
    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
    &.b{
        font-family: 'Berlingske';
        font-feature-settings: 'salt' on;
    }
}
h5 {
    font-family: "Suisse";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: 18px;
    line-height: 24px;
    color: $forest;
    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
    &.b{
        font-family: 'Berlingske';
        font-feature-settings: 'salt' on;
    }
}
p.overline {
    font-family: "Suisse";
    font-weight: light;
    font-style: normal;
    font-display: swap;
    font-size: 18px;
    line-height: 22px;
    color: $forest;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    @include mobile {
        font-size: 18px;
        line-height: 22px;
    }
}
p.accordion {
    font-family: "Suisse";
    font-weight: light;
    font-style: normal;
    font-display: swap;
    font-size: 18px;
    line-height: 24px;
    color: $grass;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}
p {
    font-family: "Suisse";
    font-weight: light;
    color: $forest;
    font-size: 18px;
    line-height: 24px;
    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
}
p.sm {
    font-family: "Suisse";
    font-weight: light;
    color: $forest;
    font-size: 16px;
    line-height: 24px;
    @include mobile {
        font-size: 16px;
        line-height: 24px;
    }
}

p.legal {
    font-family: "Suisse";
    font-weight: light;
    color: $forest;
    font-size: 14px;
    line-height: 20px;
    @include mobile {
        font-size: 14px;
        line-height: 20px;
    }
}

.btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 22px 11px;
    gap: 8px;
    width: fit-content;
    text-decoration: none;
    border: 1px solid $forest;
    border-radius: 24px;
    &:hover {
        background-color: $forest;
        p {
            color: $beige;
        }
    }
    &:active {
        background-color: $forest;
        scale: 0.95;
        p {
            color: $beige;
        }
    }
}

.btn-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px 9px;
    background-color: $forest-light;
    border-radius: 24px;
    width: fit-content;
}

.dl{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
        text-decoration: none;
    
    p{
        padding-right: 18px;
    }
}
@mixin common-padding {
    padding: 100px 40px;
    @include mobile {
        padding: 64px 20px;
    }
}

.contain-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}
.contain{
    width: 100%;
    max-width: min(100%, 2000px);
}

.img-caption{
    padding-top: 16px;
    @include mobile {
        font-size:14px;
        padding-top:8px
    }
}
