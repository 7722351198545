@import '../../scss/mixin.scss';

.contact-one{
    background-color: $forest;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 326px 0 100px;
    h1{
        color: $beige;
        width: 60%;
        max-width: 1000px;
        text-align: center;
        @include mobile{
            width: 100%;
            padding: 0 20px;
        }
    }
    @include mobile {
        box-sizing: border-box;
        height:80vh;
        padding:94px 0 0 0;
    }

}
