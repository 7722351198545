@import "../../scss/mixin.scss";

.accordion-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 56px;
    width: 50%;
    padding-right: 34px;
    @include mobile{
        width: 100%;
        padding-top: 0;
    }
    .accordion {
        cursor: pointer;
        width: 100%;
        border-bottom: 1px solid $forest;
        height: fit-content;
        padding-bottom: 16px;
        margin-bottom: 24px;
        transition: height 2s ease-in-out;
        .accordion-title {
            display: flex;
            justify-content: space-between;
            p {
                color: $grass;
                padding-left: 16px;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
            }
        }
        .accordion-content {                     
            padding: 24px 16px;
        }
        &.active{
            .accordion-content{
                height: fit-content;
            }            
        }
        &.open{
            img{
                transform: scale(-1);
            
            }
        }
    }
}
