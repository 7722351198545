@import "../../scss/mixin.scss";

.avail-hero{
    background-image: url('../../../public/images/diamonds.svg');
    background-attachment: fixed;
    background-repeat: repeat-y;    
    background-size: cover;
    @include mobile {
        background-attachment: inherit;
    } 
}


.avail-one {
    display: flex;
    height: 600px;
    justify-content: center;
    align-items: center;
    padding: 326px 0 100px;
    h1 {
        width: 60%;
        max-width: 1000px;
        text-align: center;
        @include mobile {
            width: 100%;
            padding: 0 20px;
        }
    }
    @include mobile {
        box-sizing: border-box;
        height:80vh;
        padding:94px 0 0 0;
    }
}

.avail-two {
    @include common-padding;
    display: flex;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
    }
    img {
        width: 45%;
        @include mobile {
            width: 100%;
        }
    }
    h2 {
        padding-bottom: 48px;
    }
    .table-wrapper {
        display: flex;
        flex-direction: column;
        width: 45%;
        @include mobile {
            width: 100%;
            padding-top: 56px;
        }        
        .row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 8px 0 8px 0;
            align-items: center;

         
             
                &:hover{
                    background-color: rgba($forest-light-3, 0.2);
                    cursor: pointer;
                }               
            .cell {
                width: 25%;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &:first-child {
                    text-align: left;
                    justify-content: flex-start;
                }
            }
            &.border {
                border-bottom: 2px solid $forest;
                padding-bottom: 12px;
                margin-bottom: 40px;
                &:last-child {
                    padding-top: 40px;
                }
                &:nth-child(2){
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
                &:last-child{
                    &:hover{
                        background-color: transparent !important;
                        cursor: default;
                        
                    }
                }
            }
        }
    }
}

.avail-three {
    @include common-padding;
    background-color: $ice-blue;
    
    .row p {
        @include mobile {
            flex:0 0 100px;
            text-align:right;
            &:nth-child(1) {
                text-align:left;
            }
        }
    }
}
.avail-four {
    background-color: $ice-blue;
    @include common-padding;
    .spec-container {
        display: flex;
        justify-content: space-between;
        @include mobile {
            flex-direction: column;
            padding-top: 56px;
        }
    }
}

.avail-five {
    @include common-padding;
    h2 {
        padding-bottom: 64px;
    }
    .teams {
        display: flex;
        flex-wrap: wrap;
        .team {
            width: 20%;
            padding-right: 25px;
            padding-bottom: 64px;
            p {
                padding-top: 32px;
            }
            &:nth-child(5n) {
                padding-right: 0;
            }
            @include mobile {
                width: 50%;
            }
        }
    }
}
