@import "../../scss/mixin.scss";

.footer-wrapper {
    .top-section {
        padding: 190px 0;
        display: flex;
        @include mobile {
            padding: 100px 20px;
            flex-direction: column;
        }
        .copy-wrapper {
            padding: 100px 0;
            left: 50%;
            position: relative;
            width: 50%;
            @include mobile {
                width: 100%;
                left: 0;
            }
            h2 {
                width: 75%;
                padding: 32px 0;
                @include mobile {
                    width: 80%;
                }
            }
        }
    }
    .green-wrapper{
        background-image: url('../../../public/images/green-diamond.svg');
        background-color: $forest;
        background-size: cover;
    
    .agents-section {
        display: flex;
        padding: 160px 32px 0 32px;
        @include mobile {
            flex-direction: column;
            padding: 64px 20px 0 20px;
        }        
        .left {
            width: 50%;
            @include mobile{
                width: 100%;
            }
            h2 {
                color: $beige;
            }
            p {
                color: $beige;
                &.overline {
                    padding-top: 42px;
                    padding-bottom: 24px;
                }
            }
            .btn-wrapper {
                display: flex;
                padding-bottom: 95px;
                @include mobile{
                    flex-direction: column;
                }
                .btn-pill:first-child {
                    margin-right: 10px;
                    @include mobile{
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }
                .btn-pill:hover{
                    background-color: $forest-light-2;
                    cursor: pointer;                
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;
            @include mobile{
                width: 100%;
            }
            .agents-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                @include mobile{
                    flex-direction: column;
                }
                .agents {
                    width: 50%;
                    padding-right: 10rem;
                    @include mobile{
                        padding-right: 0;
                        width: 100%;
                    }                    
                    .agent {
                        padding-bottom: 57px;
                        @include mobile{
                            padding-bottom: 37px;

                        }
                        a{
                            text-decoration: none;
                            color: $beige;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                        p {
                            color: $beige;
                        }
                        img{
                            height: 30px;
                        }
                    }
                }
            }
            .borders-wraps {
                border-top: 1px solid $forest-light-2;
                border-bottom: 1px solid $forest-light-2;
                padding: 24px 0;
                display: flex;
                align-items: center;
                @include mobile{
                    width: 100%;
                    align-items: flex-start;
                    flex-direction: column;
                }
                p {
                    padding: 0;
                    width: 50%;
                    @include mobile{
                        width: 100%;
                        padding-bottom: 5px;
                    }
                    color: $beige;
                }
                .img-wrapper {
                    padding: 0;
                }
            }
            .misrep {
                padding: 57px 0 85px 0;
                @include mobile{
                    padding: 37px 0 32px 0;
                }
                p {
                    color: $forest-light-2;
                }
            }
        }
    }
    .bottom-container { 
        padding: 0 32px;
        @include mobile{
            padding: 0 20px;
        }
        .bottom {
            padding: 32px 0 56px 0;
            border-top: 1px solid $forest-light-2;
            display: flex;
            @include mobile
            {
                padding: 32px 0 32px 0;
                flex-direction: column-reverse;
            }
            .copyright {
                width: 50%;
                display: flex;
                p {
                    padding-right: 10px;
                    color: $forest-light-2;
                }
            }
        }
    }
    .logo {
        width: 75px;
        @include mobile{
            display: none;
        }
    }
}
}

.siren{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    a{
        color: $beige;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
            text-decoration-color: $forest-light-2 ;
        }
        p{
            color: $forest-light-2;
        }
    }
}
