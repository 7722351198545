@import '../../../scss/mixin.scss';

#detail-swiper{
    position: relative;
    .swiper-slide{
        width: fit-content !important;
        img {
            height: 40vh;
            width: auto;
        }
    }
}

.pagination-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    bottom: 10% !important;

    .swiper-pagination-bullet {
        height: 2px;
        width: 24px;
        background-color: $beige;
        border-radius: 0;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        height: 2px;
        width: 80px;
    }
    @include mobile {
        padding: 8px 0;
    }
}