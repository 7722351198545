@import "../../scss/mixin.scss";

.build-hero{
  background-image: url('../../../public/images/diamonds.svg');
  background-attachment: fixed;
  background-repeat: repeat-y;    
  background-size: cover;
}

.nowrap {white-space: nowrap;font-variant-ligatures: none;}

.build-one {
  padding: 326px 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  h1 {
    width: 50%;
    max-width: 1000px;
    text-align: center;
    @include mobile {
      width: 100%;
      padding: 0 20px;
    }
  }
  @include mobile {
    box-sizing: border-box;
    height:80vh;
    padding:94px 0 0 0;
  }
}

.build-two {
  padding: 100px 32px;
  @include mobile {
    padding: 64px 20px;
  }
}

.build-three {
  display: flex;
  width: 100%;
  @include common-padding;
  @include mobile{
    flex-direction: column;
  }
  // .row {
  //   display: flex;
  //   width: 100%;
  //   padding-bottom: 88px;
  //   @include mobile{
  //       flex-direction: column;
  //       padding-bottom: 24px;
  //   }
  //   h2 {
  //     width: 50%;
  //     @include mobile{
  //       width: 100%;
  //       padding-bottom: 40px;
  //     }
  //   }
  // }
  // .number-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   @include mobile{
  //     padding-bottom: 24px;
  //   }

  //   .icon-wrapper {
  //     padding-bottom: 24px;
  //     img {
  //       width: 48px;
  //       height: 48px;
  //     }
  //   }
  //   .sqft-container {
  //     display: flex;
  //     p.sqft {
  //       font-size: 28px;
  //       line-height: 32px;
  //     }
  //   }
  //   h3 {
  //     padding-top: 25px;
  //     text-rendering: geometricPrecision;

  //   }
  // }
  // .icon-row {
  //   display: flex;
  //   padding-bottom: 88px;
  //   @include mobile{
  //       flex-direction: column;
  //       padding-bottom: 0;
  //   }
  //   .number-wrapper {
  //     width: 25%;
  //       @include mobile{
  //           width: 100%;            
  //       }
  //   }

  // }
  // h1.bigNumber{
  //   font-size: 150px;
  //   line-height: 150px;
  //   padding: 10px 0;
  //   @include mobile{
  //       font-size: 90px;
  //       line-height: 90px;
  //   }
  
  // }
  // h1.number{
  //   font-size: 100px;
  //   line-height: 100px;
  //   @include mobile{
  //     font-size: 90px;
  //     line-height: 90px;
  // }
  // }

  .copy-wrapper{
    width: 25%;
    padding-right: 3rem;
    @include mobile{
        width: 100%;
        padding-bottom: 3rem;
    }
  }

  .bento{
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    @include tablet{
      width: 100%;
      gap: 0.8rem;
    }
    .box{
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      gap: 1.5rem;
      height: 284px;
      justify-content: space-between;
      background-color: $forest;
      @include tablet{
        width: 100%;
      }
      .icon-wrapper{
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        img{
          fill: $beige;
          width:  50px;
          height: 50px;
        }
        p{
          color: white;
        }
      }
      p{
        color: white;
      }
      h1{
        color: white;
        font-size: 80px;
        line-height: 80px;
        display: flex;
        font-family: 'Berlingske';
        letter-spacing: 0.000001em;
        span{
          font-size: 30px;
          line-height: 40px;        
          padding-left: 0.5rem;
        }
        @include tablet{
          font-size: 60px;
          line-height: 60px;
        }
      }
    }
    .row-one{
      width: 100%;
      display: flex;
      gap: 0.75rem;  
      @include tablet{
        flex-direction: column;
        flex-direction: column-reverse;
      }
      .box{
        width: calc((200% / 3) - 0.8rem);
        @include tablet{
          width: 100%;
        }
      }
      .img{
        width: calc(100% / 3);
        @include tablet{
          display: none;
        }
        background-size: cover;
        background-image: url('../../../public/images/building/4.jpg');
        background-position: center;
      }
    }
    .row-two{
      width: 100%;
      display: flex;

      gap: 0.8rem;
      @include tablet{
        flex-direction: column;
      }
      .box{
        width: calc((100% / 3) - 0.8rem);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include tablet{
          width: 100%;
        }
        &:nth-child(1){
          background-color: $forest-light-3;   
          p{
            color: $forest;
          }
          h1{
            color: $forest;     
          }
        }
        &:nth-child(2){
          background-color: $forest-light-2;        
        }
        &:nth-child(3){
          background-color: $beige-dark;        
          width: calc(100% / 3);
          @include tablet{
            width: 100%;
          }
          h1{
            color: $forest;
          }
          p{
            color: $forest;
          }
        }
      }
    }
    .row-three{
      width: 100%;
      display: flex;
      gap: 0.8rem;
      @include tablet{        
        flex-direction: column;
      }
      .box{ 
        width: calc(100% / 4);
        display: flex;
        @include tablet{
          width: 100%;
        }
        &:nth-child(1){
          background-color: $forest;       
          h2{
            color: white;
          } 
          p{
            color: white;
          } 
        }
        &:nth-child(2){
          background-color: $beige-dark;       
          h2{
            color: $forest;
          } 
          p{
            color: $forest;
          }
        }
        &:nth-child(3){
          background-color: $forest-light-2;    
          h2{
            color: $forest;
          } 
          p{
            color: $forest;
          }              
        }
        &:nth-child(4){
          background-color: $forest-light;        
          h2{
            color: white;
          } 
          p{
            color: white;
          } 
        }
      }
    }
  }
}

.build-four {
  display: flex;
  @include common-padding;
  @include mobile {
    flex-direction: column;
  }
  .copy-wrapper {
    width: 25%;
    padding-right: 3rem;
    @include mobile{
        width: 100%;
    }
    p {
      padding: 24px 0;
    }
  }
  .img-wrapper {
    width: 75%;
    @include mobile{
        width: 100%;
    };
    img {
      width: 100%;
      @include mobile {
        display:block;
      }
    }
  }
}

.build-five {
  background-image: url("../../../public/images/building/2.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 0;  
  width: 100%;
  h2 {
    color: $beige;
    width: 50%;
    padding: 100px 40px 0 40px;
    @include mobile{
        width: 100%;
        padding: 0 20px;        
    }
  }
  .masonry{
    display: flex;
    gap: 1.5rem;
    padding: 40px;
    width: 100%;
    @include mobile{
      flex-direction: column;
      padding: 1.5rem;
      gap: 1;
    }
    .col{
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: calc(100% / 3);
      @include mobile{
        width: 100%;
        gap: 1rem;
      }
      .cell{
        border: 1px solid $beige;
        background-color: rgba($forest, 0.8);
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        img{
          width: 50px;
          aspect-ratio: 1/1;
        }
        h3{
          color: $beige;
        }
        p{
          color: $beige;
        }
        ul{
          padding-left: 1rem;
          li{
            color: $beige;
            padding-left: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

      }
    }
  }
}

.build-six {
  @include common-padding;
  h2 {
    width: 50%;
    padding-bottom: 56px;
    @include mobile{
        width: 100%;
    }
  }
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    @include mobile{
        flex-direction: column;
    }
    .logo {
      width: 20%;
      display: flex;
      
      flex-direction: column;
      .img-wrapper{
        display: flex;
        align-items: flex-end;
        height: 100px;
        @include mobile{
          height:auto;
          margin-top:4vh;
          
        }
      }
      @include mobile{
        width: 100%;
        border-bottom:1px solid #133c1e;
        padding-bottom:4vh;
      }
      h3 {
        padding: 40px 0 16px 0;
      }
      &:first-child{

        img{
          width: 109px  ;
        }
      }
      &:nth-child(2){
        img{
          width: 101px;
        }
      }
    }
  }
}

.build-seven{
    @include common-padding;
    h2{
      padding-bottom: 56px;
    }
}
