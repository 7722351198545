@import "../../scss/mixin.scss";

.nav-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 10;
    @include mobile {
        display: none;
    }
    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 0;
        .link {            
            text-transform: uppercase;
            color: $beige;
            text-decoration: none;
            font-size: 18px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:not(.logo){
                display: flex;
                flex-direction: column;            
            }
            p {
                font-family: "Berlingske Serif Text", serif;
                letter-spacing: 1px;
                font-weight: 300;
                font-size: 18px;
                line-height: 18px;                
            }
            &.active {                
                .border{
                    width: 100%;
                }
            }
            .border{                
                transition: width 0.3s;
                width: 0;
                height: 1px;
                background-color: $forest;
            }
            &:hover {
                .border{
                    width: 100%;                    
                }
            }
        }
    }
}

.custom-nav-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 32px 0 24px 0;
    border-bottom: 1px solid white;
    z-index: 10;
    backdrop-filter: blur(10px);
    background-color: rgba($beige, 0.6);
    @include mobile {
        display: none;
    }
    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        .link {
            padding: 0 24px;
            color: $forest;
            text-decoration: none;
            p {
                color: $forest;
                text-decoration: none;
                font-family: "Berlingske Serif Text", serif;
                font-weight: 300;
            }
            &.active {
                text-decoration: underline;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.mobile-burger {
    display: none;
    @include mobile {
        display: flex;
        position: fixed;
        z-index: 6;
        top: 24px;
        right: 24px;
        cursor: pointer;
        .line{
            width: 25px;
            height: 1px;
            background-color: $forest;
            margin: 6px 0;
            content: ' ';
        }
    }
}
.burger-menu {
    display: none;
    @include mobile {
        padding: 0 20px 56px 20px;
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: flex-end;
        backdrop-filter: blur(10px);
        background-color: rgba($beige, 0.6);

        width: 100vw;
        z-index: 5;
        height: 100vh;
        justify-content: center;
        .links {
            display: flex;
            flex-direction: column;
            padding: 56px 0;
            position: relative;
            .link {
                padding: 6px 0;
                text-decoration: none;
                &.active{
                    text-decoration: underline;
                    color: $forest;                    
                }
            }
        }
        .downloads {
            position: absolute;
            bottom: 56px;
            .title {
                padding-bottom: 24px;

            }
            a {
                margin: 0 0 10px 0;
                padding: 10px 32px 9px 32px;
                background-color: $forest-light;
                p{
                    color: $beige;                    
                }
            }
        }
    }
}
.mobile-logo{
    display: none;
    @include mobile{
        display: flex;
        position: absolute;
        top: 24px;
        left: 24px;
        img{
            height: 82px;
        }
    }
}